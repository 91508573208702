import React from 'react';

import './Bio.scss';

export const Bio = () => (
  <div className="bio-container">
    <p>
      Hi! My name's Logan Patiño. I'm a software engineer based in Cambridge, MA.
    </p>
  </div>
);
